import './scss/origin.scss'

import { swiperMv } from './ts/origin/mv'
import { topSearch } from './ts/origin/topSearch'

swiperMv()
topSearch()

const app = document.querySelector<HTMLDivElement>('#app')!

if (app) {
  app.innerHTML = `<h1>Hello Vite!</h1>`
}
