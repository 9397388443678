const AREA = [
  'all',
  'hokkaido_tohoku',
  'kanto',
  'hokuriku_koshinetsu',
  'tokai',
  'kinki',
  'chugoku_shikoku',
  'kyushu_okinawa',
]

const searchBtnDom = document.querySelector<HTMLElement>('#js-top-searchBtn')
const productsModalDom = document.querySelector<HTMLElement>(
  '#js-top-searchBoxContent_products'
)
const selectedAreaDom = document.querySelector<HTMLElement>(
  '#js-top-selectedItemArea'
)
const selectedProductsDom = document.querySelector<HTMLElement>(
  '#js-top-selectedItemProduct'
)

let checkedAreaItemValArr: string[] = []
let checkedProductItemValArr: string[] = []
let checkedItemTxtArr: string[] = []

// searchBoxBtnをクリックした際にスクロールするための関数
const clickedWindowScroll = () => {
  const topSearchBoxDOM = document.querySelector<HTMLElement>(
    '#js-top-searchBox'
  )
  const headerDOM = document.querySelector<HTMLElement>('#js-header')
  const headerDOMHeight = headerDOM?.offsetHeight
  const topSearchBoxOffset =
    topSearchBoxDOM?.getBoundingClientRect().top! +
    window.pageYOffset -
    headerDOMHeight!
  window.scroll({ top: topSearchBoxOffset })
}

// 検索項目の有無判定
const searchVoidCheck = () => {
  if (
    checkedProductItemValArr.join('%2C') !== '' ||
    checkedAreaItemValArr.length !== 0
  ) {
    searchBtnDom?.removeAttribute('disabled')
  } else {
    searchBtnDom?.setAttribute('disabled', 'true')
  }
}

// 検索する選択項目の表示有無
const isShowSearchResultContent = () => {
  const searchResultContentDOM = document.querySelector<HTMLElement>(
    '#js-top-searchResultContent'
  )
  selectedAreaDom?.textContent !== '' || checkedItemTxtArr.length !== 0
    ? searchResultContentDOM?.classList.remove('hidden')
    : searchResultContentDOM?.classList.add('hidden')
}

const areaModal = () => {
  const areaModalDom = document.querySelector<HTMLElement>(
    '#js-top-searchBoxContent_area'
  )
  const bg = document.querySelector<HTMLElement>(
    '#js-top-searchBoxContentBg_area'
  )
  const searchBtnAreaDom = document.querySelector<HTMLElement>(
    '#js-top-searchBoxBtnArea'
  )

  searchBtnAreaDom?.addEventListener('click', () => {
    clickedWindowScroll()
    areaModalDom?.classList.add('active')
  })
  bg?.addEventListener('click', () => {
    areaModalDom?.classList.remove('active')
  })
  AREA.map((el) => {
    let selecteAreaDom = document.querySelector<HTMLElement>('#js-area-' + el)
    selecteAreaDom?.addEventListener('click', () => {
      areaModalDom?.classList.remove('active')
    })
  })
}

const selectArea = () => {
  AREA.map((el) => {
    let selecteAreaDom = document.querySelector<HTMLElement>('#js-area-' + el)
    selecteAreaDom?.addEventListener('click', function () {
      selectedAreaDom!.textContent = this.textContent

      // 検索リンクパラメータ用の配列作成
      checkedAreaItemValArr.indexOf(this.dataset.value!) === -1
        ? checkedAreaItemValArr.push(this.dataset.value!)
        : (checkedAreaItemValArr = checkedAreaItemValArr.filter(
            (el) => el !== this.dataset.value
          ))

      // 検索ボタンの活性/非活性
      searchVoidCheck()
      // 検索する選択項目の活性/非活性
      isShowSearchResultContent()
    })
  })
}

const productModal = () => {
  const selectBtnProductsDom = document.querySelector<HTMLElement>(
    '#js-top-searchBtnProducts'
  )

  const bg = document.querySelector<HTMLElement>(
    '#js-top-searchBoxContentBg_products'
  )
  selectBtnProductsDom?.addEventListener('click', () => {
    clickedWindowScroll()
    productsModalDom?.classList.add('active')
  })
  bg?.addEventListener('click', () => {
    productsModalDom?.classList.remove('active')
  })
}

const selectProducts = () => {
  const productItemDomArr = document.querySelectorAll<HTMLElement>(
    '.js-top-searchBoxProductItem'
  )

  productItemDomArr.forEach((el) => {
    el.addEventListener('click', function () {
      this.classList.contains('active')
        ? this.classList.remove('active')
        : this.classList.add('active')

      // 検索欄に表示するテキスト用の配列作成
      checkedItemTxtArr.indexOf(this.textContent!) === -1
        ? checkedItemTxtArr.push(this.textContent!)
        : (checkedItemTxtArr = checkedItemTxtArr.filter(
            (el) => el !== this.textContent
          ))

      // 検索リンクパラメータ用の配列作成
      checkedProductItemValArr.indexOf(this.dataset.product!) === -1
        ? checkedProductItemValArr.push(this.dataset.product!)
        : (checkedProductItemValArr = checkedProductItemValArr.filter(
            (el) => el !== this.dataset.product
          ))

      if (selectedProductsDom) selectedProductsDom.textContent = checkedItemTxtArr.join(',')

      // 検索ボタンの活性/非活性
      searchVoidCheck()
      // 検索する選択項目の活性/非活性
      isShowSearchResultContent()
    })
  })

  // 検索ボタンの活性/非活性
  searchVoidCheck()
  // 検索する選択項目の活性/非活性
  isShowSearchResultContent()
}

const createSearchLink = () => {
  const siteLink = window.location.href

  // テストと本番環境の判別
  let baseLink: string
  siteLink.indexOf('localhost:8004') ||
  siteLink.indexOf('hapilogi.sakura.ne.jp')
    ? (baseLink =
        'https://service-connect-dev.hapilogi.co.jp/logistics/shipper/search-partner/result')
    : (baseLink =
        'https://service-connect.hapilogi.co.jp/logistics/shipper/search-partner/result')

  searchBtnDom?.addEventListener('click', () => {
    let openSiteLink: string = baseLink
    if (
      checkedProductItemValArr.join('%2C') !== '' &&
      checkedAreaItemValArr.join('%2C') !== ''
    ) {
      openSiteLink =
        openSiteLink +
        '?search_product_handle=' +
        checkedProductItemValArr.join('%2C') +
        '&prefectures=' +
        checkedAreaItemValArr.join('%2C')
    } else if (
      checkedProductItemValArr.join('%2C') !== '' &&
      checkedAreaItemValArr.join('%2C') === ''
    ) {
      openSiteLink =
        openSiteLink +
        '?search_product_handle=' +
        checkedProductItemValArr.join('%2C')
    } else if (
      checkedProductItemValArr.join('%2C') === '' &&
      checkedAreaItemValArr.join('%2C') !== ''
    ) {
      openSiteLink =
        openSiteLink + '?prefectures=' + checkedAreaItemValArr.join('%2C')
    }
    window.location.href = openSiteLink
  })
}

export const topSearch = () => {
  areaModal()
  productModal()
  createSearchLink()
  isShowSearchResultContent()
  selectArea()
  selectProducts()
}
