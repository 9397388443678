import Swiper, { Autoplay, EffectFade, SwiperOptions } from 'swiper'

const swiperMvParams: SwiperOptions = {
  modules: [Autoplay, EffectFade],
  spaceBetween: 30,
  effect: 'fade',
  loop: true,
  speed: 2000,
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
}

export const swiperMv = () => {
  new Swiper('.swiperMv', swiperMvParams)
}
